<template>
  <div class="player_puplayer-demo">
    <h3>视频播放 PuPlayer</h3>
    <div>{{ $route.query.id }}</div>
    <p>
      <button @click="puList">查看设备列表1</button>
      <select style="width:150px" @change="dange">
        <Option>请选择设备</Option>
        <Option v-for="(item,index) in id" :key="index">{{ item.id }}</Option>
      </select>
      <label>通道号：</label
      ><input
        v-model.number="index"
        type="text"
        placeholder="通道号 （数字）"
    />
    </p>
    <p>
      <!-- 初始化和销毁 -->
      <template>
        <button v-if="!videoFlag" style="margin-bottom: 20px" @click="startVideo">开始录像</button>
        <br>
        <button v-if="videoFlag" style="margin-bottom: 20px" @click="catchVideo">抓拍照片</button>
        <br>
        <button v-if="videoFlag" style="margin-bottom: 20px" @click="endVideo">结束录像</button>
        <br>

        <button style="margin-bottom: 20px" @click="addDwonPlan">添加下载计划</button>
        <br>

        <button style="margin-bottom: 20px" @click="getDwonPlan">获取下载计划列表</button>
        <br>
        <select style="width:150px;margin-bottom: 20px">
          <Option>下载计划列表</Option>
          <Option v-for="(item,index) in planList" :key="index">{{ item.name }}</Option>
        </select><br>
        <button style="margin-bottom: 20px" v-if="!videoFlag" @click="getVideoList">获取文件列表</button>
        <br>

        <!--        <a-tag v-for="(item, index) in fileList" @click="downFile(item)" :key="index">{{ item.fileName }}</a-tag>-->
        <select style="width:150px;margin-bottom: 20px">
          <Option>文件列表</Option>
          <Option v-for="(item,index) in fileList" :key="index">{{ item.fileName }}</Option>
        </select><br>

        <button style="margin-bottom: 20px" v-if="!videoFlag" @click="getSeVideoList">获取设备文件列表</button>
        <br>
        <select style="width:150px;margin-bottom: 20px">
          <Option>设备文件列表</Option>
          <Option v-for="(item,index) in fileSeList" :key="index">{{ item.fileName }}</Option>
        </select><br>


        <button style="margin-bottom: 20px" @click="getDeviceFiles">设备文件检索</button>
        <br>

        <button style="margin-bottom: 20px" @click="getPlatformFiles">平台文件检索</button>
        <table cellspacing="0px" v-show="recordFiles.length>0" border="1px">
          <tr align="center">
            <td>puID</td>
            <td>fileType</td>
            <td>fileName</td>
            <td>channelIndex</td>
            <td>beginTime</td>
            <td>endTime</td>
            <td>fileSize</td>
            <td>download</td>
            <td>play</td>
          </tr>
          <tr v-for="(item, index) in recordFiles" :key="index" v-show="item.fileType == 'video'" align="center">
            <td>{{ item.puID }}</td>
            <td>{{ item.fileType }}</td>
            <td>{{ item.fileName }}</td>
            <td>{{ item.channelIndex }}</td>
            <td>{{ formatPts(item.beginTime) }}</td>
            <td>{{ formatPts(item.endTime) }}</td>
            <td>{{ Number(item.fileSize / 1024 / 1024).toFixed(2) }}M</td>
            <td>
              <button @click="downloadFiles(item.fileID)">download</button>
            </td>
            <td>
              <button @click="playFiles(item.fileID)">play</button>
            </td>
          </tr>
        </table>
      </template>
    </p>
    <div class="playback-player" v-if="mediaUrl" @click="mediaUrl = ''">
      <video :src="mediaUrl" class="video" controls id="playback-video"></video>
    </div>
    <p>
      <!-- 初始化和销毁 -->
      <template>
        <button v-if="!instance" @click="init">初始化播放器</button>
        <button v-else @click="destroy">销毁播放器</button>
      </template>
    </p>
    <!-- 播放器容器 -->
    <div ref="playerContainer" class="player-container"></div>
    <p v-if="instance">
      <!-- 打开和关闭 -->
      <template>
        <button v-if="closed" @click="open">建立连接</button>
        <button v-else @click="close">关闭连接</button>
      </template>

      <!-- 视频如何适应容器 -->
      <template>
        <label>适应容器：</label>
        <select :value="videoFit" @change="handleVideoFit">
          <option value="contain">保持比例</option>
          <option value="fill">填充</option>
        </select>
      </template>

      <!-- 显示和隐藏 -->
      <template>
        <button v-if="hidden" @click="display">显示</button>
        <button v-else @click="hide">隐藏</button>
      </template>

      <!-- 移动和更换容器 -->
      <template>
        <button @click="move">更换容器</button>
      </template>
    </p>
    <!-- 播放器容器2 -->
    <div ref="playerContainer2" class="player-container2"></div>
    <h3>回调</h3>
    <p>
      <button v-if="messages.length" @click="() => (messages = [])">
        清空
      </button>
    </p>
    <pre>{{ messages.join("\n") }}</pre>
  </div>
</template>

<script>
import {PuPlayer} from "@besovideo/webrtc-player";

export default {
  name: "PuPlayerDemo",
  props: {
    token: String,
  },
  data() {
    return {
      r: "",
      id: [],
      fileList: [],
      fileSeList: [],
      planList: [],
      index: 0,
      videoFit: "fill",

      instance: null,
      closed: true,
      hidden: false,
      moved: false,
      videoFlag: false,
      messages: [],
      recordFiles: [],
      puid: "",
      mediaUrl: "",
    };
  },
  mounted() {
    // 初始化
    console.log(this.$route.query)
  },
  methods: {
    // 初始化
    init() {
      if (!this.token || !this.id) return;
      const {instance} = PuPlayer({
        // (可选) 容器节点 注意一个容器内只能存在一个实例  当container为假值（包括false、null、undefined）时 将返回实例引用的dom节点 容器必须指定高度 参考高德地图
        container: this.$refs.playerContainer,
        // 必填 设备选项
        puOption: {
          // 设备id
          id: this.r,
          // 设备通道号
          index: this.index,
        },
        // 必填 用户授权令牌
        token: this.token,
        videoFit: this.videoFit,
        // (可选) 双击是否全屏
        fullScreenOnDblclick: true,
        type: "webrtc" | "ws-bvrtc" | "auto",
        // (可选) 启用控制器
        enableController: true,
        onConnected: () => {
          this.messages.push("onConnected 连接已建立");
        },
        onConnectedFailed: () => {
          this.messages.push("onConnectedFailed 连接建立失败");
        },
        onClose: () => {
          this.messages.push("onClose 连接已关闭（播放器关闭）");
        },
        onDisConnected: () => {
          this.messages.push("onDisConnected  连接已关闭（服务器断开连接）");
        },
        onDestroy: () => {
          this.messages.push("onDestroy 播放器实例已销毁");
        },
      });

      this.instance = instance;
    },

    // 设备选择
    dange(e) {
      this.r = e.target.value;
      this.puid = e.target.value;
      console.log(this.r);
    },
    //开始录像
    async startVideo() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/pu/manualrecord/start/" + this.puid + "/0", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            video: true,
            audio: true,
            stopTime: 0,
            fileName: '12345'
          }),
        });
        console.log('>>>>>>>>>>startVideo>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          //console.log(JSON.stringify(res));
          this.videoFlag = true,
              console.log('>>>>>>>>>>>>>startVideo>>>>>>>>>>>>>>', r.ok);
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //抓拍照片
    async catchVideo() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/pu/manualsnapshot/one/" + this.puid + "/0", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },

        });
        console.log('>>>>>>>>>>startVideo>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          //console.log(JSON.stringify(res));
          this.videoFlag = true,
              console.log('>>>>>>>>>>>>>startVideo>>>>>>>>>>>>>>', r.ok);
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //结束录像
    async endVideo() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/pu/manualrecord/stop/" + this.puid + "/0", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            video: true,
            audio: true,
            stopTime: 0,
            fileName: '12345'
          }),
        });
        console.log('>>>>>>>>>>endVideo>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          this.videoFlag = false,
              console.log('>>>>>>>>>>>>>endVideo>>>>>>res>>>>>>>>', r.ok);
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //获取上传到服务器的文件
    async getVideoList() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/recordfile/filter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 20,
            filter: {
              begintime: 1712877009,
              puID: this.puid,
            }
          }),
        });
        console.log('>>>>>>>>>>endVideo>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {

          const res = await r.json();
          console.log('111111111111111', res);
          let data = res.data;
          console.log('>>>>>>>>>>>>>datadata>>>>>>>>>>>>>>', data);
          this.fileList = [];
          if (data) {
            data.forEach((item) => {
              // if (item.fileType=="video") {
              let obj = {
                fileName: item.fileName,
                fileID: item.fileID,
              };
              console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>', obj);
              this.fileList.push(obj);
              // }
            });
          }
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //获取设备文件
    async getSeVideoList() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/monitor/puFileList", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 1,
            filter: {
              ChannelIndex: 0,
              PuId: this.puid,
            }
          }),
        });
        console.log('>>>>>>>>>>endVideo>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {

          const res = await r.json();
          console.log('111111111111111', res);
          let data = res.data;
          console.log('>>>>>>>>>>>>>datadata>>>>>>>>>>>>>>', data);
          this.fileSeList = [];
          if (data) {
            data.forEach((item) => {
              //if (item.fileType=="video") {
              let obj = {
                fileName: item.fileName,
                fileID: item.fileID,
              };
              console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>', obj);
              this.fileSeList.push(obj);
              //}
            });
          }
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },

    //添加下载计划
    async addDwonPlan() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/plan/download", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            name: '下载计划001',
            fileTypeList: ['video', 'audio', 'image'],
            onlineModeList: ['wifi', 'wireless', 'wired'],
            TimeSlice: [],
          }),
        });
        console.log('>>>>>>>>>>addDwonPlan>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          console.log('111111111111111', res);
          let data = res.data;
          console.log('>>>>>>>>>>>>>addDwonPlan>>>>>>>>>>>>>>', data);
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //获取下载计划列表
    async getDwonPlan() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/plan/download/query", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 10,

          }),
        });
        console.log('>>>>>>>>>>getDwonPlan>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          console.log('111111111111111', res);
          let data = res.data;
          console.log('>>>>>>>>>>>>>getDwonPlan>>>>>>>>>>>>>>', data);
          this.planList = [];
          if (data) {
            data.forEach((item) => {
              //if (item.fileType=="video") {
              let obj = {
                name: item.name,
                uid: item.uid,
                fileTypeList: item.fileTypeList
              };
              console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>', obj);
              this.planList.push(obj);
              //}
            });
          }
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },

    //下载文件
    async downFile(record) {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/pu/download/UA_2D325585/" + record.fileID, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        });
        console.log('>>>>>>>>>>downFile>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          console.log('>>>>>>>>>>>>>downFile>>>>>>>>>>>>>>', r.ok);
          //console.log(this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    //获取设备列表
    async puList() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/pu/list", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 1000,
            needStatus: true
          }),
        });
        console.log('>>>>>>>>>>rrrrr>>>>>>>>>>>>>>>>>', r);
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          //console.log(JSON.stringify(res));
          let data = res.data;
          console.log('>>>>>>>>>>>>>datadata>>>>>>>>>>>>>>', data);
          this.id = [];
          if (data) {
            data.forEach((item) => {
              if (item.status == 1) {
                let obj = {
                  id: item.id,
                };
                console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>', obj);
                this.id.push(obj);
              }
            });
          }
          console.log('111111111-----------', this.id);
          return;
        }
        //throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },

    async getPlatformFiles() {
      if (!this.token) return;
      try {
        const r = await fetch("/bvcsp/v1/recordfile/filter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 100,
            filter: {
              beginTime: 946656000,
              endTime: Date.parse(new Date()) / 1000,
              match: '12345',
              puID: this.puid
            },
          }),
        });

        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          console.log(JSON.stringify(res));
          if (res.code == 0) {
            let data = res.data;
            this.recordFiles = [];
            if (data) {
              data.forEach((item) => {
                let obj = {
                  id: item.id,
                  fileID: item.fileID,
                  nrulID: item.nrulID,
                  puID: item.puID,
                  userID: item.userID,
                  channelIndex: item.channelIndex,
                  filePath: item.filePath,
                  fileType: item.fileType,
                  fileSize: item.fileSize,
                  fileHash: item.fileHash,
                  beginTime: item.beginTime,
                  endTime: item.endTime,
                  insertTime: item.insertTime,
                  recordReason: item.recordReason,
                  desc1: item.desc1,
                  desc2: item.desc2,
                  fileName: item.fileName,
                  url: "/bvnru/v1/download/" + item.fileID,
                };
                this.recordFiles.push(obj);
              });
            }
          }
          return;
        }
        throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    async getDeviceFiles() {
      if (!this.token) return;
      try {
        console.log("PTS = " + Date.parse(new Date()) / 1000);
        let puid = this.puid;
        const r = await fetch("/bvcsp/v1/pu/recordfile/filter/" + puid, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          body: JSON.stringify({
            page: 0,
            pageSize: 100,
            filter: {
              beginTime: 946656000,
              endTime: Date.parse(new Date()) / 1000,
              puID: puid,
              userID: "",
              fileType: [],
              recordReason: [],
              channelIndex: -1,
              desc1: "",
              desc2: "",
              match: "",
            },
          }),
        });

        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          console.log(JSON.stringify(res));
          if (res.code == 0) {
            let data = res.data;
            this.recordFiles = [];
            if (data) {
              data.forEach((item) => {
                let obj = {
                  id: 0,
                  fileID: item.fileID,
                  nrulID: "",
                  puID: puid,
                  userID: "",
                  channelIndex: item.channelIndex,
                  filePath: item.filePath,
                  fileType: item.fileType,
                  fileSize: item.fileSize,
                  fileHash: "",
                  beginTime: item.beginTime,
                  endTime: item.endTime,
                  insertTime: item.insertTime,
                  recordReason: item.recordReason,
                  desc1: item.desc1,
                  desc2: item.desc2,
                  fileName: "",
                  url: "",
                };
                let len = obj.filePath.length;
                let startLen = obj.filePath.lastIndexOf('/') + 1;
                obj.fileName = obj.filePath.substring(startLen, len);
                console.log("filePath:" + obj.filePath + ", fileName:" + obj.fileName + ", len:" + len + ", sl:" + startLen);
                obj.url = "/bvcsp/v1/pu/download/" + puid + "/" + obj.fileID;
                this.recordFiles.push(obj);
              });
            }
          }
          return;
        }
        throw new Error(`${r.status} ${r.statusText}`);
      } catch (e) {
        console.error(e);
      }
    },
    async downloadFiles(fileid) {
      if (!this.token || this.recordFiles.length <= 0) return;
      try {
        console.log("PTS = " + Date.parse(new Date()) / 1000);
        let obj = this.recordFiles.find(item => {
          return item.fileID == fileid;
        });
        if (!obj)
          throw new Error(`file is not exist!`);
        console.log("url:" + obj.url + ", name:" + obj.fileName);

        let createObjectURL = function (object) {
          return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
        }
        let xhr = new XMLHttpRequest();
        let formData = new FormData();
        xhr.open('get', obj.url);  //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
        xhr.setRequestHeader("Authorization", this.token);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (this.status == 200) {
            let blob = this.response;
            let filename = obj.fileName;
            console.log(this.response)
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, filename);
            } else {
              let a = document.createElement('a');
              let url = createObjectURL(blob);
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
          }
        };
        xhr.send(formData);
      } catch (e) {
        console.error(e);
      }
    },
    playFiles(fileid) {
      if (!this.token || this.recordFiles.length <= 0) return;
      try {
        console.log("PTS = " + Date.parse(new Date()) / 1000);
        let obj = this.recordFiles.find(item => {
          return item.fileID == fileid;
        });
        if (!obj || !obj.url)
          throw new Error(`file is not exist!`);
        console.log('1111111111111111', document.cookie)
        this.mediaUrl = obj.url;
      } catch (e) {
        console.error(e);
      }
    },
    cleanData() {
      this.mediaUrl = "";
      this.recordFiles = [];
    },
    formatInt(num, len) {
      if (typeof (num) != 'number' || typeof (len) != 'number')
        return null;
      let str = num.toString();
      if (str.length < len) {
        let diff = len - str.length;
        while (diff > 0) {
          str = "0" + str;
          diff--;
        }
      }
      return str;
    },
    formatPts(pts) {
      //shijianchuo是整数，否则要parseInt转换
      let fixZero = this.formatInt;
      var time = new Date(pts * 1000);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return fixZero(y, 4) + '/' + fixZero(m, 2) + '/' + fixZero(d, 2) + ' ' + fixZero(h, 2) + ':' + fixZero(mm, 2) + ':' + fixZero(s, 2);
    },
    // 销毁
    destroy() {
      // destroy前会执行close
      this.instance?.destroy();
      this.instance = null;
      this.closed = true;
    },
    // 打开连接
    async open() {
      if (!this.instance) return;
      try {
        await this.instance.open();
        this.closed = false;
      } catch (e) {
        console.log(e);
      }
    },
    // 关闭连接
    close() {
      this.instance?.close();
      this.closed = true;
    },
    // 改变videoFit
    handleVideoFit(e) {
      console.log('1111111111',e)
      if (!this.instance) return;
      const v = e.target.value;
      this.instance.setVideoFit(v);
      this.videoFit = v;
    },
    // 显示
    display() {
      this.instance?.display();
      this.hidden = false;
    },
    // 隐藏
    hide() {
      this.instance?.hidden();
      this.hidden = true;
    },
    // 移动和更换容器
    move() {
      this.instance?.moveTo(
          this.moved ? this.$refs.playerContainer : this.$refs.playerContainer2
      );
      this.moved = !this.moved;
    },
  },
};
</script>

<style scoped lang="less">
.player_puplayer-demo {
  .player-container {
    height: 350px;
    width: 500px;
    border: 1px solid #ddd;
  }

  .player-container2 {
    margin-top: 8px;
    height: 250px;
    width: 330px;
    border: 1px solid #ddd;
  }

  pre {
    line-height: 1.5;
    font-size: 15px;
  }
}
</style>
